import React, { useCallback, useEffect, useState } from "react"

import CountUp from "react-countup"
import {
  getRandomElement,
  getRandomInt,
  logNormalRandom,
  normalRandom,
} from "../../utils/random"
import { useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const MAX_ROWS = 6
const INTERVAL = 2500

const hash = row => {
  return Object.values(row).join("")
}

const headlineOptions = [
  "8 Benefits Of AAA",
  "8 Simple Ways to Travel Smarter",
  "8 Smart Travel Tips for the Perfect Trip",
  "8 Travel Tips to Help You Travel Smarter",
  "Benefits for AAA Membership Levels",
  "AAA Membership: Is It Worth the Cost?",
  "AAA Membership Perks Most Don't Know",
  "Top Discounts for AAA Members",
  "5 Surprise Travel Expenses!",
]

const channels = ["Taboola", "Meta", "Google Ads"]
const devices = ["Desktops", "Smartphones"]
const interests = ["Travel", "Vacation", "Personal Finance"]

const CreativeBiddingSection = () => {
  const query = useStaticQuery(graphql`
    query {
      allFile(filter: { relativeDirectory: { eq: "creatives" } }) {
        nodes {
          childImageSharp {
            fluid(
              maxWidth: 200
              maxHeight: 100
              quality: 100
              cropFocus: CENTER
            ) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)

  const Image = ({ image, ...props }) => {
    return <Img fluid={image} {...props} />
  }

  const images = query.allFile.nodes.map(node => node.childImageSharp.fluid)

  const makeRow = useCallback(() => {
    return {
      headline: getRandomElement(headlineOptions),
      image: getRandomElement(images),
      interest: getRandomElement(interests),
      channel: getRandomElement(channels),
      device: getRandomElement(devices),
      budget: 200 + 100 * logNormalRandom(),
      bid: normalRandom(0.4, 0.05),
    }
  }, [images])

  const [rows, setRows] = useState(
    Array.from({ length: MAX_ROWS + 1 }, () => makeRow())
  )

  useEffect(() => {
    const timeout = setTimeout(() => {
      const newRow = makeRow()
      setRows([newRow, ...rows].slice(0, MAX_ROWS + 1))
    }, INTERVAL)

    return () => clearTimeout(timeout)
  }, [rows])

  return (
    <div className="creative-bidding-section">
      <div
        className="table-grid"
        style={{
          height: (MAX_ROWS + 1) * 3.5 + "rem",
        }}
      >
        <div className="table-header">
          <div>Creative</div>
          <div>Channel</div>
          <div>Interest</div>
          <div>Device</div>
          <div>Bid</div>
          <div>Budget</div>
        </div>
        {rows.map((row, i) => (
          <div
            key={i + hash(row)}
            className={[
              "table-row",
              "animated",
              i === 0 ? "fadeInLeft" : "slideInDown",
            ].join(" ")}
          >
            <div className="creative-cell">
              <Image
                className="creative-image"
                image={row.image}
                alt={row.headline}
              />
              <div className="creative-headline">{row.headline}</div>
            </div>
            <div>{row.channel}</div>
            <div>{row.interest}</div>
            <div>{row.device}</div>
            <div className={i === 0 ? "hl" : ""}>
              $
              {i === 0 ? (
                <>
                  {row.bid.toFixed(2)}
                  <span className="star"></span>
                </>
              ) : (
                row.bid.toFixed(2)
              )}
            </div>
            <div
              className={i === 0 ? "hl" : ""}
              style={{ animationDelay: "1.5s", animationDuration: "1s" }}
            >
              <div
                className={i === 0 ? "animated fadeInLeft" : ""}
                style={{ animationDelay: "1.5s" }}
              >
                ${row.budget.toFixed(2)}
              </div>
              {i === 0 && (
                <span
                  className="star"
                  style={{ animationDelay: "1.5s", animationDuration: "1s" }}
                ></span>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default CreativeBiddingSection
