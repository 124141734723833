import React from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"

function slugToTitle(slug) {
  return slug
    .split("-")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ")
}

const CaseStudyCard = ({ caseStudy, wide = false }) => {
  const caseStudyLink = `/case-studies/${caseStudy.slug}`
  return (
    <Link
      to={caseStudyLink}
      className={`case-study-card ${wide ? "case-study-card--wide" : ""}`}
    >
      <div className="case-study-card--title">
        <h2
          className="
            is-size-3
            has-text-weight-bold
            has-text-dark
          "
        >
          {slugToTitle(caseStudy.slug)}
        </h2>
        <p className="has-text-dark is-size-5">{caseStudy.title}</p>
      </div>
      <div className="case-study-card--image">
        <div className="case-study-card--hero">
          <Img
            fluid={caseStudy.hero.localFile?.childImageSharp.fluid}
            alt={caseStudy.title}
          />
        </div>
        <div className="case-study-card--logo">
          <Img
            fluid={caseStudy.logo.localFile?.childImageSharp.fluid}
            alt={caseStudy.title}
          />
        </div>
      </div>

      <div className="case-study-card--results">
        <div>
          <p className="is-size-7 has-text-dark">{caseStudy.label_1}</p>
          <p className="is-size-4 has-text-weight-bold has-text-dark">
            {caseStudy.text_1}
          </p>
          <p className="is-size-7 has-text-dark">{caseStudy.label_1_bottom}</p>
        </div>
        <div>
          <p className="is-size-7 has-text-dark">{caseStudy.label_2}</p>
          <p className="is-size-4 has-text-weight-bold has-text-dark">
            {caseStudy.text_2}
          </p>
          <p className="is-size-7 has-text-dark">{caseStudy.label_2_bottom}</p>
        </div>

        <div>
          <p className="is-size-7 has-text-dark">{caseStudy.label_3}</p>
          <p className="is-size-4 has-text-weight-bold has-text-dark">
            {caseStudy.text_3}
          </p>
          <p className="is-size-7 has-text-dark">{caseStudy.label_3_bottom}</p>
        </div>
      </div>

      <div className="case-study-card--link button is-rounded is-link">
        View case study
      </div>
    </Link>
  )
}

export default CaseStudyCard
