import { useStaticQuery } from "gatsby"
import React, { useEffect, useState } from "react"

import Img from "gatsby-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"

const naHeadlines = [
  "Not a AAA member yet? Sign up today to be fully prepared for your summer travel plans",
  "Discover the full list of membership benefits including discounts and perks that can pay for themselves",
  "Not a AAA member yet? Sign up today to get discounts and perks for your summer travel plans",
  "How Much Does AAA Membership Cost? Compare Different Plans and Prices Here",
  "Discover the full list of membership benefits including discounts and perks that will pay for themselves",
  "See how AAA members can save on everything from hotels, car rentals, auto repair and more!",
]

const NextActionsSection = () => {
  const query = useStaticQuery(graphql`
    query {
      allFile(filter: { relativeDirectory: { eq: "creatives" } }) {
        nodes {
          childImageSharp {
            fluid(
              maxWidth: 200
              maxHeight: 100
              quality: 100
              cropFocus: CENTER
            ) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      nextActionsScreenshot: file(
        relativePath: { eq: "next-actions/screenshot.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const imageOptions = query.allFile.nodes

  const [currentImage, setCurrentImage] = useState(0)
  const [oldImage, setOldImage] = useState(imageOptions.length - 1)
  const [currentHeadline, setCurrentHeadline] = useState(0)
  const [oldHeadline, setOldHeadline] = useState(naHeadlines.length - 1)

  useEffect(() => {
    const interval = setInterval(() => {
      setOldImage(currentImage)
      setCurrentImage((currentImage + 1) % imageOptions.length)

      setOldHeadline(currentHeadline)
      setCurrentHeadline((currentHeadline + 1) % naHeadlines.length)
    }, 3000)

    return () => clearInterval(interval)
  })

  return (
    <div className="next-actions-section">
      <div className="phone-mockup">
        <Img fluid={query.nextActionsScreenshot.childImageSharp.fluid} />

        <div
          className="next-action-mock animated fadeOutLeft"
          key={oldImage + ":" + oldHeadline}
        >
          <div className="next-action-mock-image">
            <Img fluid={imageOptions[oldImage].childImageSharp.fluid} />
          </div>
          <div className="next-action-mock-headline">
            <p>
              {naHeadlines[oldHeadline]} <FontAwesomeIcon icon={faArrowRight} />
            </p>
          </div>
        </div>

        <div
          className="next-action-mock animated slideInRight"
          key={currentImage + ":" + currentHeadline}
        >
          <div className="next-action-mock-image">
            <Img fluid={imageOptions[currentImage].childImageSharp.fluid} />
          </div>
          <div className="next-action-mock-headline">
            <p>
              {naHeadlines[currentHeadline]}{" "}
              <FontAwesomeIcon icon={faArrowRight} />
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NextActionsSection
