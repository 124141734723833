import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AgenciesMarquee from "../components/agencies-marquee"
import ContactFormFields from "../components/contact-form-fields"
import { useRef, useState } from "react"
import submitContactForm from "../utils/contact"
import { faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import VariationsSection from "../components/index-page/variations-section"
import NextActionsSection from "../components/index-page/next-actions-section"
import CaseStudiesSection from "../components/case-studies-section"
import { Link } from "gatsby"
import RequestDemoSection from "../components/request-demo-section"

import taboolaLogo from "../images/channel-logos/taboola-fit.svg"
import inpoweredLogo from "../images/inpowered-logo-light.svg"
import CreativeBiddingSection from "../components/index-page/creative-bidding-section"

const title = "Boost Taboola Performance"
const description =
  "Activate inPowered AI on top of your existing Taboola campaigns to maximize performance and increase ROAS."
const slug = "native"
const subTitle = "AI Increases Taboola Campaign Performance"

const biddingTitle = "AI Bid & Budget Allocation Algos Maximize Performance"
const biddingDescription =
  "Trained on aggregated historical conversion data across verticals and your first party data, inPowered AI dynamic bid and budget algorithms optimize your media spend in Taboola in real-time by allocating the ideal bid and budget per ad variant to maximize performance at scale."
const creativeTitle = "AI Generates Creatives That Drive Results"
const creativeDescription =
  "inPowered AI is trained on aggregated vertical-level conversion data to generate the ideal creative variations predicted to maximize your KPI. All you need to do is select your desired outcomes. Your team maintains creative control by reviewing every variant and approving them before going live."
const nextActionTitle = "AI CTAs Significantly Increase Conversion Rates"
const nextActionDescription =
  "inPowered AI places smart CTAs across your campaign landing pages mapped back to your objective. By generating the right CTAs at the right time, the AI increases landing page conversion rates and maximizes your Taboola campaign performance across channels."

const brands = [
  {
    name: "The Motley Fool",
    logo: require("../images/brand-logos/the-motley-fool.svg"),
  },
  // {
  //   name: "Yamaha",
  //   logo: require("../images/brand-logos/yamaha.svg"),
  // },
  {
    name: "AAA",
    logo: require("../images/brand-logos/aaa.svg"),
  },
  {
    name: "McAfee",
    logo: require("../images/brand-logos/mcafee.svg"),
  },
  {
    name: "TD Bank",
    logo: require("../images/brand-logos/td-bank.svg"),
  },
  {
    name: "Qualcomm",
    logo: require("../images/brand-logos/qualcomm.svg"),
  },
  {
    name: "Charles Schwab",
    logo: require("../images/brand-logos/charles-schwab.svg"),
  },
  {
    name: "Ninjatrader",
    logo: require("../images/brand-logos/ninjatrader.svg"),
  },
  {
    name: "Netjets",
    logo: require("../images/brand-logos/netjets.svg"),
  },
  {
    name: "NRG",
    logo: require("../images/brand-logos/nrg.svg"),
  },
]

const LandingPage = () => {
  const contactForm = useRef()

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState("")
  const [success, setSuccess] = useState(false)

  const submitForm = async event => {
    event.preventDefault()
    setIsLoading(true)
    setError("")

    const { success, error } = await submitContactForm(contactForm.current)
    setIsLoading(false)

    if (!success) {
      setError(error)
      return
    }

    contactForm.current.reset()
    setSuccess(true)
  }

  return (
    <Layout
      navigation={[
        {
          to: "#request-demo",
          text: "Request Demo",
          isButton: true,
        },
      ]}
    >
      <SEO title={title + " with inPowered AI"} description={description} />
      <section className="hero landing-hero">
        <div class="gradient-bg has-background-dark">
          <div class="gradients-container">
            <div class="gradient g1"></div>
            <div class="gradient g2"></div>
            <div class="gradient g3"></div>
            <div class="gradient g4"></div>
            <div class="gradient g5"></div>
          </div>
        </div>
        <div className="hero-body animated fadeIn has-text-centered pt-5">
          <div className="container px-3">
            <div className="columns is-flex-gap-6">
              <div
                className="column"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "flex-start",
                }}
              >
                <h1 className="animated fadeIn has-text-left title has-text-white has-text-weight-medium">
                  {title}
                </h1>
                <p
                  className="is-size-1-desktop
                  is-size-3-touch has-text-white"
                >
                  with inPowered AI
                </p>
                <h2 className="animated fadeIn has-text-left subtitle has-text-white has-text-weight-light pt-4 is-size-4-desktop">
                  {description}
                </h2>

                <div
                  className="columns is-mobile my-3"
                  style={{ maxWidth: 500 }}
                >
                  <img
                    className="column is-4"
                    src={taboolaLogo}
                    alt="Taboola"
                  />

                  <div className="column is-size-3 has-text-white is-2 is-align-items-center is-flex is-justify-content-center">
                    <span className="">+</span>
                  </div>

                  <img
                    className="column is-5"
                    src={inpoweredLogo}
                    alt="inPowered AI"
                  />
                </div>
              </div>
              <div className="column is-5">
                {/* Card */}
                <div
                  className="card animated fadeIn"
                  style={{ animationDelay: "0.5s", borderRadius: 20 }}
                >
                  <div className="card-content">
                    <h3 className="title is-2 has-text-dark has-text-weight-bold">
                      Request a <span className="has-text-link">Demo</span>
                    </h3>
                    <div className="contact-form has-text-left">
                      <form ref={contactForm}>
                        <input
                          type="hidden"
                          name="formPlacement"
                          value={`Landing Page (${slug}) - Top`}
                        />
                        <ContactFormFields
                          success={false}
                          staticFields={{
                            topic: "Request Demo",
                            businessType: "An Agency",
                            message:
                              "I'm interested in learning more about inPowered AI.",
                          }}
                        ></ContactFormFields>

                        {success ? (
                          <button
                            className="button is-rounded is-medium is-link"
                            disabled
                          >
                            Thank you!
                          </button>
                        ) : isLoading ? (
                          <button
                            className="button is-rounded is-medium is-link"
                            disabled
                          >
                            <FontAwesomeIcon
                              icon={faSpinner}
                              fixedWidth
                              spin
                              size="2x"
                            />
                          </button>
                        ) : (
                          <button
                            className="button is-rounded is-medium is-link"
                            onClick={event => submitForm(event)}
                          >
                            Submit
                          </button>
                        )}

                        <p>{error}</p>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <AgenciesMarquee
          title="Join Leading Brands Activating inPowered AI"
          elements={brands}
        ></AgenciesMarquee>
      </section>

      <div className="home-articles has-background-light py-6">
        <section className="container px-4 ">
          <div className="mb-6">
            <h2 className="title is-2 has-text-weight-medium has-text-dark has-text-centered mb-4">
              {subTitle} by <span className="has-text-link">50% to 300%</span>
            </h2>
          </div>

          <article className="home-article">
            <h3 className="title is-2 my-0 has-text-weight-bold has-text-dark">
              {biddingTitle}
            </h3>
            <p className="description is-size-5 has-line-height-4">
              {biddingDescription}
            </p>
            <div className="visuals">
              <CreativeBiddingSection></CreativeBiddingSection>
            </div>
          </article>

          <article className="home-article reverse">
            <h3 className="title is-2 my-0 has-text-weight-bold has-text-dark">
              {creativeTitle}
            </h3>
            <p className="description is-size-5 has-line-height-4">
              {creativeDescription}
            </p>
            <div className="visuals">
              <VariationsSection></VariationsSection>
            </div>
          </article>

          <article className="home-article">
            <h3 className="title is-2 my-0 has-text-weight-bold has-text-dark">
              {nextActionTitle}
            </h3>
            <p className="description is-size-5 has-line-height-4">
              {nextActionDescription}
            </p>
            <div className="visuals">
              <NextActionsSection></NextActionsSection>
            </div>
          </article>
        </section>
      </div>
      <section className="container px-4 ">
        <div className="my-6 is-flex-desktop is-justify-content-space-between">
          <h2 className="title is-size-2 is-size-3-mobile has-text-weight-medium has-text-dark">
            AI-Powered <span className="has-text-link">Results</span>
          </h2>

          <Link
            to="/case-studies"
            className="button is-rounded is-link is-hidden-touch"
          >
            All Case Studies
          </Link>
        </div>
        <CaseStudiesSection
          limit={2}
          ignoreSlugs={["ohio-liquor", "connecticut-childrens"]}
        />
      </section>

      <div id="request-demo" className="pb-5">
        <RequestDemoSection
          showDescription={false}
          formPlacement={`Landing Page (${slug}) - Bottom`}
          staticFields={{
            topic: "Request Demo",
            businessType: "An Agency",
            message: "I'm interested in learning more about inPowered AI.",
          }}
        ></RequestDemoSection>
      </div>
    </Layout>
  )
}

export default LandingPage
