import { graphql, Link, StaticQuery } from "gatsby"
import React from "react"
import CaseStudyCard from "./case-study/case-study-card"

export default ({ limit = Number.POSITIVE_INFINITY, ignoreSlugs = [] }) => (
  <StaticQuery
    query={graphql`
      query {
        allWordpressAcfCaseStudies {
          nodes {
            id
            acf {
              label_1
              label_1_bottom
              label_2
              label_2_bottom
              label_3
              label_3_bottom
              slug
              summary
              text_1
              text_2
              text_3
              title
              logo {
                localFile {
                  childImageSharp {
                    fluid(
                      maxHeight: 160
                      maxWidth: 160
                      quality: 80
                      cropFocus: CENTER
                    ) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              hero {
                localFile {
                  childImageSharp {
                    fluid(
                      maxHeight: 337
                      maxWidth: 524
                      quality: 80
                      cropFocus: CENTER
                    ) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <div className="container pb-6 ">
        <div className="case-studies-grid">
          {data.allWordpressAcfCaseStudies.nodes
            .filter(caseStudy => !ignoreSlugs.includes(caseStudy.acf.slug))
            .slice(0, limit)
            .map((caseStudy, index) => (
              <CaseStudyCard
                caseStudy={caseStudy.acf}
                key={caseStudy.id}
                wide={
                  index === 2 &&
                  data.allWordpressAcfCaseStudies.nodes.length % 2 === 1
                }
              />
            ))}
        </div>
      </div>
    )}
  />
)
